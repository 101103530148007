import { storage, getDownloadURL, ref } from '../firebase';

export async function getImageFirebase(location) {
    // console.log('locationURL', location);
    // let url;
    const starRef = ref(storage , location);
    // const ImageURL = getDownloadURL(starRef)
    const ImageURL = await getDownloadURL(starRef).catch(); // (error) => console.log(error)
    // await getDownloadURL(starRef).then((imgUrl) => {
    //     // console.log(imgUrl);
    //     url = imgUrl;
    // }).catch((error) => {
    //     console.log(error);
    // })

    return ImageURL
    // console.log('letURL',  url);
}
