import { Backdrop, Box, Button, Modal, Stack, Typography, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import Iconify from "../Iconify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    bgcolor: 'background.paper',
    width: '25vw',
    boxShadow: 24,
    p: 3,
};

// Modal used to confirm whether a refund is to  be made or not
export default function ConfirmRefundModal({ confirmModal, handleClose, tripId, reason, amount, buttonStyle, confirmButtonStyle, handleConfirm }) {

    const userId = Cookies.get('userId');
    const twoDigit = (+amount).toFixed(2).toString();
    const refundValue = {
        tripId,
        amount: twoDigit,
        refundReason: reason,
        adminUserId: userId,
    }

    const theme = useTheme();

    const CustomBackdrop = (props) => {
        return (
            <Backdrop
                {...props}
                sx={{
                    backdropFilter: 'blur(6px)', // Apply the blur effect
                    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Optional: semi-transparent background
                }}
            />
        );
    };

    return (
        <Modal open={confirmModal} BackdropComponent={CustomBackdrop}>
            <Box sx={style}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 65,
                        height: 65,
                        borderRadius: '50%',
                        bgcolor: theme.palette.info.lighter,
                        p: 0.5,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 45,
                            height: 45,
                            borderRadius: '50%',
                            bgcolor: theme.palette.info.light
                        }}
                    >
                        <Iconify icon="ri:building-line" width={24} height={24} />
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: 'fontWeightBold', mb: 2, mt: 2 }}>Please check details before proceeding</Typography>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Typography sx={{fontWeight: 'fontWeightBold'}} variant="body1">Trip Id :</Typography>
                            <Typography variant="body1" sx={{ ml: 0.5 }}>{tripId}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Typography sx={{fontWeight: 'fontWeightBold'}} variant="body1">Amount : </Typography>
                            <Typography variant="body1" sx={{ ml: 0.5 }}>{twoDigit}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Typography sx={{fontWeight: 'fontWeightBold'}} variant="body1">Reason :</Typography>
                            <Typography variant="body1" sx={{ ml: 0.5 }}>{reason}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center', pt: 2 }}>
                    <Button sx={{ ...buttonStyle, flexGrow: 1 }} onClick={() => handleClose()} >Cancel</Button>
                    <Button sx={{ ...confirmButtonStyle, flexGrow: 1 }} onClick={() => handleConfirm(refundValue)}>Confirm Refund</Button>
                </Box>
            </Box>
        </Modal>
    )
}