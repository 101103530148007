import React from 'react';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Bookings',
    path: '/dashboard/bookings',
    icon: getIcon('mdi:ticket'),
    role: { read: true },
    module: 'verification',
  },
  {
    title: 'Drivers',
    path: '/dashboard/driver',
    icon: getIcon('mdi:card-account-details'),
    role: { read: true },
    module: 'driver',
  },
  {
    title: 'Vehicles',
    path: '/dashboard/vehicle',
    icon: getIcon('mdi:van-passenger'),
    role: { read: true },
    module: 'vehicle',
  },
  {
    title: 'Admin Users',
    path: '/dashboard/user',
    icon: getIcon('mdi:account-box'),
    role: { read: true },
    module: 'vehicle',
  },
  {
    title: 'E-KYC Verification',
    path: '/dashboard/verification',
    icon: getIcon('mdi:shield-check'),
    role: { read: true },
    module: 'verification',
  }
];

export default navConfig;
