import { alpha, Backdrop, Box, Button, Modal, Typography, useTheme } from "@mui/material";
import Iconify from "./Iconify";
import { useEffect } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    bgcolor: 'background.paper',
    width: '25vw',
    boxShadow: 24,
    p: 3,
};


// New delete modal component created
export default function ModalDelete({ handleDelete, deleteModal, data, handleClose }) {

    const theme = useTheme();

    const cancelButtonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };
    
    const deleteButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.error.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.error.main, 0.85)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.dark,
            color: 'white',
        },
    }   

    const getTypeAndTitle = (data) => {
        if (data?.driverImageURI) {
          return { type: 'driver', title: 'Delete Driver', info: `${data.firstName} ${data.lastName}` };
        }
        if (data?.vehicleNumber) {
          return { type: 'vehicle', title: 'Delete Vehicle', info: data.vehicleNumber };
        }
        if (data?.firebaseUUID) {
            return {type: 'user', title: 'Delete Admin User', info: data.firstName};
        }
        return { type: 'default', title: 'Please check details before proceeding', info: '' };
      };
      
      const { type, title, info } = getTypeAndTitle(data);
      

    const CustomBackdrop = (props) => {

        return (
            <Backdrop
                {...props}
                sx={{
                    backdropFilter: 'blur(6px)', // Apply the blur effect
                    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Optional: semi-transparent background
                }}
            />
        );
    };

    return (
        <Modal open={deleteModal} BackdropComponent={CustomBackdrop}>
            <Box sx={style}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 65,
                        height: 65,
                        borderRadius: '50%',
                        bgcolor: theme.palette.error.lighter,
                        p: 0.5,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 45,
                            height: 45,
                            borderRadius: '50%',
                            bgcolor: theme.palette.error.light
                        }}
                    >
                        <Iconify icon="eva:trash-2-outline" sx={{ color: theme.palette.error.main }} width={24} height={24} />
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
                        {title}
                    </Typography>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
                            <Typography variant="body1">This is a big deal! We're double checking just in case.</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Typography variant="body1">
                                Are you sure you want to delete{' '}
                                <Typography component="span" variant="body1" sx={{ fontWeight: 'fontWeightBold' }}>
                                    {info}
                                </Typography>
                                ? This process is irreversible.
                            </Typography>
                        </Box>

                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center', pt: 2 }}>
                    <Button sx={{ ...cancelButtonStyle, flexGrow: 1 }} onClick={() => handleClose()} >Cancel</Button>
                    <Button sx={{ ...deleteButtonStyle, flexGrow: 1 }} onClick={() => handleDelete(data)}>Delete</Button>
                </Box>
            </Box>
        </Modal>
    )
};