import { Box, Typography, Button } from "@mui/material"
import { alpha, useTheme } from "@mui/material/styles"
import { useEffect, useState } from "react"

// Custom pagination reusable component created to be used for all tables within the admin console
export default function CustomPagination({ page, totalNum, rowsPerPage, handleChangePage }) {

    const [maxPage, setMaxPage] = useState(0);

    const theme = useTheme()

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08)
        },
    }

    const fontStyle = {
        fontWeight: 'fontWeightMedium'
    }

    useEffect(() => {
        setMaxPage(Math.ceil(totalNum / rowsPerPage))
    }, [totalNum])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
            {maxPage > 0 ? (
                <>
                    <Typography sx={{ color: theme.palette.grey[700] }}>
                        Page {page + 1} of {maxPage}
                    </Typography>
                </>
            ) : (
                <Typography sx={{ color: theme.palette.grey[700] }}>
                    Page 0
                </Typography>
            )}

            <Box>
                <Button sx={buttonStyle}
                    onClick={() => handleChangePage(null, page - 1)}
                    disabled={page === 0}
                >
                    <Typography sx={fontStyle}>
                        Previous
                    </Typography>
                </Button>
                <Button sx={buttonStyle}
                    onClick={() => handleChangePage(null, page + 1)}
                    disabled={(page + 1) * rowsPerPage >= totalNum}
                >
                    <Typography sx={fontStyle}>
                        Next
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}