import { alpha, Avatar, Box, Button, FormControl, TextField, Typography, useTheme } from "@mui/material";
import BackButton from "../backButton";
import { useEffect, useState } from "react";
import { getImageFirebase } from "src/utils/getImageFirebase";
import VerificationModal from "./VerificationModal";
import { APIUpdateValidateUser } from "src/utils/apiCalled";

export default function PageVerification({ handleClosePageVerification, user, setOpen, setSuccMsg, setErrMsg, setVariant, setMainMsg, setModalTriggerAPICalled }) {

    const [userImg, setUserImage] = useState(null);
    const [nricImg, setNricImage] = useState(null);
    const [rejectReason, setRejectReason] = useState('')
    const [loading, setLoading] = useState(false);
    const [verificationModal, setVerificationModal] = useState(false);
    const [action, setAction] = useState(null);

    const theme = useTheme();

    const fontHeaderStyle = {
        color: theme.palette.grey[700],
        fontWeight: 'fontWeightMedium',
        mr: 5,
        minWidth: '250px'
    }

    const formControlStyle = {
        mb: 3,
        display: 'flex',
        flexDirection: 'row',

    }

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };

    const confirmButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.90)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.dark,
            color: 'white',
        },
    }

    const getImageUser = (location, setState) => {
        getImageFirebase(location)
            .then((img) => {
                setState(img);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (user) {
            // Fetch and set the user image
            getImageUser(user.userImageURI, setUserImage);

            // Fetch and set the NRIC image
            getImageUser(user.nricImageURI, setNricImage);
        }
    }, [user]);

    const handleToggleVerificationModal = (action) => {
        setVerificationModal(!verificationModal);
        setAction(action);
    }

    const removeAllState = () => {
        setUserImage(null);
        setNricImage(null);
        setVerificationModal(false);
        setAction(null);
        setRejectReason("");
        handleClosePageVerification();
    };

    const handleReject = async () => {
        try {
            const reducer = {
                phone: user.phone,
                verificationStatus: 'UNVERIFIED',
                rejectionReason: rejectReason,
            }
            const result = await APIUpdateValidateUser(setLoading, reducer)
            if (result.status === 200) {
                setLoading(false);
                setOpen(true);
                setVariant('success');
                setMainMsg('E-KYC Verification Rejected');
                setSuccMsg('Verification has been sucessfully rejected');
                setModalTriggerAPICalled(true);
                removeAllState()
            } else {
                setLoading(false);
                setOpen(true);
                setVariant('error');
                setErrMsg('Error!, Please try again');
                setMainMsg('Error Encountered');
                removeAllState()
            }

        } catch (err) {
            setLoading(false);
            setOpen(true);
            setVariant('error');
            setErrMsg(`Error! ${err}`);
            setMainMsg('Error Encountered');
            removeAllState()
        }
    };

    const handleApprove = async (value) => {
        try {
            const reducer = {
                phone: user.phone,
                verificationStatus: 'VERIFIED'
            }
            // console.log('recducer apprive', reducer)
            const result = await APIUpdateValidateUser(setLoading, reducer)
            if (result.status === 200) {
                setLoading(false);
                setOpen(true);
                setVariant('success');
                setMainMsg('E-KYC Verification Approved')
                setSuccMsg('Verification has been sucessfully approved');
                setModalTriggerAPICalled(true)
                removeAllState()
            } else {
                setLoading(false);
                setOpen(true);
                setVariant('error');
                setErrMsg('Error!, Please try again');
                setMainMsg('Error Encountered');
                removeAllState()
            }

        } catch (err) {
            setLoading(false);
            setOpen(true);
            setVariant('error');
            setErrMsg(`Error! ${err}`);
            setMainMsg('Error Encountered');
            removeAllState()
        }
    };

    return (
        <>
            <Box sx={{ mt: 2 }}></Box>
            <BackButton back={handleClosePageVerification} type={'verification'} />
            <Box sx={{ mt: 2, pb: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="h4">E-KYC Verification</Typography>
                </Box>
            </Box>
            <Box sx={{ padding: 1, borderTop: '1px solid', borderColor: theme.palette.grey[300], display: 'flex' }}>
                <Box sx={{ width: '70%', mt: 2 }}>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Full Name</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.fullName ? user.fullName : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Nationality</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.nationality ? user.nationality : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Passport Expiry</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.passportExpiry ? user.passportExpiry : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>Deep Face Remarks</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={user.rejectionReason ? user.rejectionReason : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>User Image</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '75%', alignItems: 'center' }} >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <a
                                    href={userImg}
                                    rel="noopener norefferer"
                                    target="_blank"
                                >
                                    <Avatar src={userImg} sx={{
                                        height: 150,
                                        width: 150,
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '3px solid',
                                        borderColor: theme.palette.grey[200]
                                    }}
                                    />
                                </a>
                            </Box>
                        </Box>
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontHeaderStyle}>
                            User E-KYC Image
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '75%', alignItems: 'center' }}>
                            <a
                                href={nricImg}
                                rel="noopener norefferer"
                                target="_blank"
                            >
                                <Avatar src={nricImg} sx={{
                                    height: 180,
                                    width: 310,
                                    borderRadius: '5%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '3px solid',
                                    borderColor: theme.palette.grey[200]
                                }}
                                />
                            </a>
                        </Box>
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'right', borderTop: '1px solid', borderColor: theme.palette.grey[300], pt: 3 }}>
                <Button sx={buttonStyle} onClick={() => handleToggleVerificationModal("reject")}>
                    <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                        Reject
                    </Typography>
                </Button>
                <Button sx={confirmButtonStyle} onClick={() => handleToggleVerificationModal("approve")}>
                    <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                        Approve
                    </Typography>
                </Button>
            </Box>
            {<VerificationModal
                user={user}
                verificationModal={verificationModal}
                buttonStyle={buttonStyle}
                handleToggleVerificationModal={handleToggleVerificationModal}
                action={action}
                approveButtonStyle={confirmButtonStyle}
                rejectReason={rejectReason}
                setRejectReason={setRejectReason}
                setLoading={setLoading}
                handleReject={handleReject}
                handleApprove={handleApprove}
            />}
        </>
    )
}