import { useTheme } from "@emotion/react";
import { Box, FormControl, Grid, ListItemIcon, TextField, Typography } from "@mui/material";
import { capitalCase } from "change-case";
import { fDateTime } from "src/utils/formatTime";
import Iconify from "../Iconify";
import Label from "../Label";

// Component used to show the various details of a booking
export default function BookingsDetails({ booking, fontHeaderStyle, formControlStyle }) {
    const theme = useTheme();

    return (
        <>
            <Box sx={{ width: '70%', mt: 2 }}>
                {/* Other FormControls */}
                <FormControl sx={formControlStyle}>
                    <Typography sx={fontHeaderStyle}>Name</Typography>
                    <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.userName ? booking.userName : 'None'} />
                </FormControl>
                <FormControl sx={formControlStyle}>
                    <Typography sx={fontHeaderStyle}>Email</Typography>
                    <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.email ? booking.email : 'None'} />
                </FormControl>
                <FormControl sx={formControlStyle}>
                    <Typography sx={fontHeaderStyle}>Trip Id</Typography>
                    <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.tripId ? booking.tripId : 'None'} />
                </FormControl>
                <FormControl sx={formControlStyle}>
                    <Typography sx={fontHeaderStyle}>Transaction Id</Typography>
                    <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.transactionId ? booking.transactionId : 'None'} />
                </FormControl>
                <FormControl sx={formControlStyle}>
                    <Typography sx={fontHeaderStyle}>Fare</Typography>
                    <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.paymentAmount ? `MYR ${(+booking.paymentAmount).toFixed(2)}` : 'None'} />
                </FormControl>

                {/* Refund Details Section */}
                {(booking.manualRefundAmount || booking.refundAmount) && (
                    <FormControl sx={{ mb: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography sx={fontHeaderStyle}>Refund Details</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                {/* Auto Refund */}
                                {booking.refundAmount > 0 && (  // Check if refundAmount is greater than 0
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 2, ml: 1, borderBottom: '1px solid', borderColor: theme.palette.grey[300] }}>
                                            {/* Larger box for the icon */}
                                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '50px', height: '54px' }}>
                                                <Iconify icon="eva:monitor-outline" width={24} height={24} color={theme.palette.grey[500]} />
                                            </Box>

                                            {/* Smaller box for the text details */}
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body1" sx={{ color: theme.palette.grey[700], fontWeight: 'fontWeightMedium', mr: 1.5 }}>
                                                        {`MYR ${booking.refundAmount}`}
                                                    </Typography>
                                                    <Label variant="filled"><Typography variant="body2" sx={{ fontWeight: 'fontWeightBold' }}>Auto</Typography></Label>
                                                </Box>
                                                {/*
                                                <Typography variant="body1" sx={{ mt: 1, color: theme.palette.grey[600] }}>
                                                    {(() => {
                                                        const refundDate = new Date(booking.refundTime);
                                                        const options = { day: 'numeric', month: 'short' }; // Short month format
                                                        const formattedDate = refundDate.toLocaleDateString("en-US", options);
                                                        const time = refundDate.toLocaleString("en-US", { hour: 'numeric', minute: 'numeric', hour12: true });
                                                        return `${formattedDate} at ${time.replace(':', '.')}`; // Replace ':' with '.' in the time
                                                    })()}
                                                </Typography>
                                                */}
                                                <Typography variant="body1" sx={{ mt: 1, color: theme.palette.grey[600] }}>
                                                    Auto Refund Completed
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}

                                {/* Manual Refund */}
                                {booking.manualRefundDetails && Array.isArray(booking.manualRefundDetails) && booking.manualRefundDetails.length > 0 && (
                                    <Grid item xs={12}>
                                        {/* Loop through manualRefundDetails for multiple refunds */}
                                        {booking.manualRefundDetails.map((refund, index) => (
                                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', pb: 2, ml: 1, borderBottom: '1px solid', borderColor: theme.palette.grey[300] }}>
                                                {/* Larger box for the icon */}
                                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '50px', height: '54px' }}>
                                                    <Iconify icon="eva:monitor-outline" width={24} height={24} color={theme.palette.grey[500]} />
                                                </Box>

                                                {/* Smaller box for the text details */}
                                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography variant="body1" sx={{ color: theme.palette.grey[700], fontWeight: 'fontWeightMedium', mr: 1.5 }}>
                                                            {`MYR ${refund.amount}`}
                                                        </Typography>
                                                        <Label variant="filled"><Typography variant="body2" sx={{ fontWeight: 'fontWeightBold' }}>Manual</Typography></Label>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', mt: 1 }}>
                                                        <Typography variant="body1" sx={{ color: theme.palette.grey[600] }}>{`${refund.refundAdminEmail}`}</Typography>
                                                        <Iconify icon="mdi:dot" width={24} height={24} sx={{ color: theme.palette.grey[600] }} />
                                                        <Typography variant="body1" sx={{ color: theme.palette.grey[600] }}>
                                                            {(() => {
                                                                const refundDate = new Date(refund.timestamp);
                                                                const options = { day: 'numeric', month: 'short' }; // Short month format
                                                                const formattedDate = refundDate.toLocaleDateString("en-US", options);
                                                                const time = refundDate.toLocaleString("en-US", { hour: 'numeric', minute: 'numeric', hour12: true });
                                                                return `${formattedDate} at ${time.replace(':', '.')}`; // Replace ':' with '.' in the time
                                                            })()}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </FormControl>
                )}

                {/* Trip Details Section */}
                <FormControl sx={{ mb: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Typography sx={fontHeaderStyle}>Trip Details</Typography>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography sx={fontHeaderStyle}>Zone Name</Typography>
                                <TextField disabled={true} fullWidth value={booking.zoneName ? booking.zoneName : 'None'} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={fontHeaderStyle}>Booking Time</Typography>
                                <TextField disabled={true} fullWidth value={booking.createdAt ? fDateTime(booking.createdAt) : 'None'} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={fontHeaderStyle}>Driver Name</Typography>
                                <TextField disabled={true} fullWidth value={booking.driverName ? booking.driverName : 'None'} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={fontHeaderStyle}>Vehicle Number</Typography>
                                <TextField disabled={true} fullWidth value={booking.vehicleNumber ? booking.vehicleNumber : 'None'} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={fontHeaderStyle}>Number of Passengers</Typography>
                                <TextField disabled={true} fullWidth value={booking.demand ? booking.demand : 'None'} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={fontHeaderStyle}>Status</Typography>
                                <TextField disabled={true} fullWidth value={booking.status ? capitalCase(booking.status.replace(/_/g, " ")) : 'None'} />
                            </Grid>
                        </Grid>
                    </Box>
                </FormControl>
            </Box>
        </>
    )
}
