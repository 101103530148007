import React, { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { AlertTitle, Box } from '@mui/material'; 
import Iconify from './Iconify';
import { mt } from 'date-fns/locale';

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} {...props} />);

const MuiSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .SnackbarItem-variantSuccess': {
    backgroundColor: theme.palette.success.dark, // your custom color here
  },
  '& .SnackbarItem-variantError': {
    backgroundColor: theme.palette.error.main, // your custom color here
  },
}));

export default function SnackBar({
  alert, open, handleClose, errorMsg, successMsg, mainMsg
}) {
  // this to display the snackbar color based on alert status
  const severityAlert = (value) => {
    switch (value) {
      case 'success':
        return 'success';
      case 'error':
        return 'error';
      default:
        return 'success';
    }
  };

  const circleColor = (value) => {
    switch (value) {
      case 'success':
        return 'success.lighter';  // Use MUI success palette
      case 'error':
        return 'error.lighter';  // Use MUI error palette
      default:
        return 'grey.200';  // Default light grey color
    }
  };

  const iconColor = (value) => {
    switch (value) {
      case 'success':
        return 'success.dark';  // Darker green color for success
      case 'error':
        return 'error.main';  // Darker red color for error
      default:
        return 'grey.600';  // Default grey color for other statuses
    }
  };

  return (
    <MuiSnackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert 
        icon={
          <Box 
            sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 56,  // Set the width for the outer circle
              height: 56, // Set the height for the outer circle
              borderRadius: '50%', // Make it circular
              bgcolor: 'grey.50', // Outer box with grey.50 color
              p: 0.5, // Add padding to create space around the icon
            }}
          >
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                width: 40, 
                height: 40, 
                borderRadius: '50%',
                bgcolor: circleColor(alert),  // Set dynamic circle color here
              }}
            >
              <Iconify icon="majesticons:key-line" sx={{ color: iconColor(alert) }} />  {/* Dynamic icon color */}
            </Box>
          </Box>
        } 
        onClose={handleClose} 
        severity={severityAlert(alert)} 
        sx={{ width: '100%', color: 'grey.600', bgcolor: 'background.paper',}}
      >
        <AlertTitle sx={{ color: 'grey.900', mb: 0.5, mt: 0.8 }}>{mainMsg}</AlertTitle>
        <Box sx={{ mt: -0.5, lineHeight: 1.2 }}>
        {
          alert === 'success' ? <div>{successMsg}</div> : <div>{errorMsg}</div>
        }
        </Box>
      </Alert>
    </MuiSnackbar>
  );
}
