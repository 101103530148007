import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import Cookies from 'js-cookie';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Driver from './pages/Driver';
import Vehicle from './pages/Vehicle';
import User from './pages/User';
import VerificationModule from './pages/VerificationModule';
import NotFound from './pages/Page404';
import UserBookings from './pages/Bookings';
import { auth } from './firebase';

// ----------------------------------------------------------------------

export default function Router() {

  // const navigate = useNavigate();
  // const email = Cookies.get('email');
  // const [indicator, setIndicator] = useState({
  //   dashboard: false,
  //   login: false
  // })

  // to call settingPage
  // useEffect(() => {
  // this function is to check if the user authenticate or not.
  // if authenticate (have value in cookies) it will navigate to
  //  onAuthStateChanged(auth, (user) => {
  //    console.log('userss', user);
  //    if (user) {
  //      navigate('/dashboard/app', { replace: true });
  //      setIndicator({
  //       dashboard: true,
  //       login: false
  //      })
  //    } else {
  //     navigate('/login', { replace: true });
  //     setIndicator({
  //       dashboard: false,
  //       login: true
  //      })
  //    }
  //  })
  //  return () => {
  //   unlisten();
  //  }
  // }, []);

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'driver', element: <Driver /> },
        { path: 'vehicle', element: <Vehicle /> },
        { path: 'user', element: <User />},
        { path: 'verification', element: <VerificationModule />},
        { path: 'bookings', element: <UserBookings />},
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/bookings" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
