/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Downshift from 'downshift';

export default function TagsInput({ ...props }) {
  const {
    selectedTags, placeholder, tags, type, sx, dupFilter, setDupFilter, selectList, keyName, setGetFilter, getFilter, ...other
  } = props;
  const [inputValue, setInputValue] = useState('');
  // console.log('dupfilter', dupFilter);
  // const [selectedItem, setSelectedItem] = useState([]);

  // useEffect(() => {
  //   setDupFilter({ ...dupFilter, [keyName]: tags });
  // }, [tags]);
  // console.log('duplicatedFIlter', dupFilter);
  // console.log('inputDups', inputValue);

  // useEffect(() => {
  //   selectedTags(dupFilter[keyName]);
  // }, [dupFilter]);

  // console.log(selectedItem);

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      const newSelectedItem = [...dupFilter[keyName]];
      const duplicatedValues = newSelectedItem.indexOf(
        event.target.value.trim(),
      );

      if (duplicatedValues !== -1) {
        setInputValue('');
        return;
      }
      if (!event.target.value.replace(/\s/g, '').length) return;

      newSelectedItem.push(event.target.value.trim());
      const arrayJoin = newSelectedItem ? newSelectedItem.join() : null;
      setGetFilter({ ...getFilter, [keyName]: arrayJoin });
      setDupFilter({ ...dupFilter, [keyName]: newSelectedItem });
      setInputValue('');
    }
    // if (
    //   dupFilter[keyName].length
    //     && !inputValue.length
    //     && event.key === 'Backspace'
    // ) {
    //   // setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
    //   setDupFilter({ ...dupFilter, [keyName]: dupFilter[keyName].slice(0, dupFilter[keyName].length - 1) });
    // }
  }
  function handleChange(item) {
    let newSelectedItem = [...dupFilter[keyName]];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue('');
    const arrayJoin = newSelectedItem ? newSelectedItem.join() : null;
    setGetFilter({ ...getFilter, [keyName]: arrayJoin });
    setDupFilter({ ...dupFilter, [keyName]: newSelectedItem });
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...dupFilter[keyName]];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    const arrayJoin = newSelectedItem ? newSelectedItem.join() : null;
    setGetFilter({ ...getFilter, [keyName]: arrayJoin });
    setDupFilter({ ...dupFilter, [keyName]: newSelectedItem });
  };

  const onChangeAutocomplete = (e, value) => {
    const arrayJoin = value ? value.join() : null;
    setGetFilter({ ...getFilter, [keyName]: arrayJoin });
    setDupFilter({ ...dupFilter, [keyName]: value });
  }

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }
  return (
    <>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={dupFilter[keyName]}
      >
        {({ getInputProps }) => {
          const {
            onBlur, onChange, onFocus, ...inputProps
          } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          });
          return (
            <div>
                {
                    type === 'text' ? (
                    <TextField
                        sx={sx}
                        InputProps={{
                          startAdornment: dupFilter[keyName].map((item) => (
                            <Chip
                              key={item}
                              tabIndex={-1}
                              label={item}
                              onDelete={handleDelete(item)}
                              sx={{ marginRight: 1 }}
                            />
                          )),
                          onBlur,
                          onChange: (event) => {
                            handleInputChange(event);
                            onChange(event);
                          },
                          onFocus,
                        }}
                        {...other}
                        {...inputProps}
                      />
                    ) : (
                        <Autocomplete
                            multiple
                            sx={sx}
                            id="tags-outlined"
                            options={selectList}
                            value={dupFilter[keyName]}
                            label={keyName}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            onChange={onChangeAutocomplete}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label={keyName}
                                // placeholder="add tags"
                            />
                        )}
                      />
                    )
                }

            </div>
          );
        }}
      </Downshift>
    </>
  );
}
TagsInput.defaultProps = {
  tags: [],
};
TagsInput.propTypes = {
  selectedTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};
