import { alpha, Box, Button, FormControl, TextField, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import ConfirmRefundModal from "./ConfirmRefundModal";

// This component is used to give the user the ability to refund
export default function RefundDetails({ booking, handleClose, handleConfirm, maxAmount, fontStyleHeader, formControlStyle }) {
    const [disabledButton, setDisabledButton] = useState(true);
    const [reasonValue, setReasonValue] = useState('');
    const [amountValue, setAmountValue] = useState(0);
    const [confirmModal, setConfirmModal] = useState(false);
    const [amountValueError, setAmountValueError] = useState(false);
    const [amountValueErrorMessage, setAmountValueErrorMessage] = useState('');

    const theme = useTheme();

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };

    const confirmButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.92)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.dark,
            color: 'white',
        },
    }

    useEffect(() => {
        if ((reasonValue == null || reasonValue.trim() === '') || (amountValue == null || amountValue <= 0 || isNaN(amountValue) || amountValue > maxAmount)) {
            setDisabledButton(false)
        } else {
            setDisabledButton(true)
        }
    }, [reasonValue, amountValue])

    const amountOnChange = (e) => {
        const target = parseFloat(e.target.value) || 0;
        setAmountValue(target);

        if (target > maxAmount) {
            setAmountValueError(true);
            setAmountValueErrorMessage('Refund amount exceeds the maximum refund amount possible.');
        }
        else {
            setAmountValueError(false);
            setAmountValueErrorMessage('');
        }
    };

    const reasonOnChange = (e) => {
        const target = e.target.value;
        setReasonValue(target);
    };

    const handleOpenConfirmModal = () => {
        setConfirmModal(true);
    }

    const handleCloseConfirmModal = () => {
        setConfirmModal(false);
    }

    return (
        <>
            <Box sx={{ width: '70%', mt: 2 }}>
                {/* Other FormControls */}
                <Box sx={{ mb: 3, borderBottom: '1px solid', borderColor: theme.palette.grey[300] }}>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontStyleHeader}>Trip Id</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.tripId ? booking.tripId : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontStyleHeader}>Transaction Id</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.transactionId ? booking.transactionId : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontStyleHeader}>Email</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.email ? booking.email : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontStyleHeader}>Total Fare</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.paymentAmount ? `MYR ${(+booking.paymentAmount).toFixed(2)}` : 'None'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontStyleHeader}>Auto Refunded Amount</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.refundAmount ? `MYR ${booking.refundAmount}` : 'MYR 0.00'} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Typography sx={fontStyleHeader}>Manual Refunded Amount</Typography>
                        <TextField disabled={true} sx={{ flexGrow: 1 }} value={booking.manualRefundAmount ? `MYR ${(+booking.manualRefundAmount).toFixed(2)}` : 'None'} />
                    </FormControl>
                </Box>
                <Box sx={{ mb: 3, borderBottom: '1px solid', borderColor: theme.palette.grey[300] }}>
                    <FormControl sx={formControlStyle}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={fontStyleHeader}>
                                Refund  <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>Enter amount you would like to refund.</Typography>
                        </Box>
                        <TextField sx={{ width: '100%' }}
                            error={amountValueError}
                            helperText={amountValueErrorMessage ? amountValueErrorMessage : ''}
                            label="Refund Amount"
                            onChange={(e) => amountOnChange(e)}
                            type='number' // Only allows numbers to be written
                            inputProps={{
                                min: 0, // Prevent Negative numbers from being written
                            }}
                            onKeyPress={(e) => {
                                if (!/[0-9.]/.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }} />
                    </FormControl>
                    <FormControl sx={formControlStyle}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={fontStyleHeader}>
                                Refund Reason <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>Provide the reason for refunding.</Typography>
                        </Box>
                        <TextField sx={{ width: '100%' }}
                            label="Refund Reason"
                            id="outlined-required"
                            onChange={(e) => reasonOnChange(e)} />
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Button sx={buttonStyle} onClick={() => handleClose()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button sx={confirmButtonStyle} disabled={!disabledButton} onClick={() => handleOpenConfirmModal()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Continue
                        </Typography>
                    </Button>
                </Box>
            </Box>
            {confirmModal && <ConfirmRefundModal handleClose={handleCloseConfirmModal}
                confirmModal={confirmModal}
                tripId={booking.tripId}
                reason={reasonValue}
                amount={amountValue}
                buttonStyle={buttonStyle}
                confirmButtonStyle={confirmButtonStyle}
                handleConfirm={handleConfirm} />}
        </>
    )
}
