import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, useScrollTrigger } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { LoginForm } from '../sections/authentication/login';
import AuthSocial from '../sections/authentication/AuthSocial';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

// This is a test comment

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  maxWidth: 850,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center', // Center items horizontally
  boxShadow: 'none',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  height: '100vh',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(12),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  }
}));

// ----------------------------------------------------------------------

export default function Login() {

  const theme = useTheme();

  return (
    <RootStyle title="Login">
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout> */}

      <SectionStyle>
        <Stack sx={{ width: '50%', alignItems: 'left' }}> {/* Centered Stack */}
          <Typography width={216} height={130} sx={{ mt: '15px' }}>
            <img src="https://storage.googleapis.com/asiamobiliti-static-assets/trek_logomark.png" alt="Trek Logo" />
          </Typography>
          <Typography variant="h2" sx={{ px: 1, color: theme.palette.grey[900] }}>
            Log in
          </Typography>
          <Typography sx={{ px: 1, mt: 1, mb: 5, textAlign: 'left', fontSize: '18px', color: theme.palette.grey[600] }}>
            Welcome back! Click the button below to sign in.
          </Typography>
          <Stack sx={{ mb: 5 }}>
            <LoginForm />
          </Stack>
          <Typography
            sx={{ px: 1, mb: 5, textAlign: 'left', fontSize: '14px', position: 'absolute', bottom: 0, left: 0, ml: 2, color: theme.palette.grey[600] }}>
            Copyright © {new Date().getFullYear().toString()} Asia Mobility Technologies Sdn Bhd. All Rights Reserved.
          </Typography>
        </Stack>
      </SectionStyle>
      <Container disableGutters sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <ContentStyle>
          <Stack sx={{ mb: 5, borderRadius: '8px', ml: 5}}>
          <Typography variant='h2' sx={{ px: 1, mt: 8, textAlign: 'left', color: theme.palette.grey[900], fontWeight: 400, ml: 2 }}>
            Trek Rides Admin Console
          </Typography>
          <Typography sx={{ px: 1, mt: 1, mb: 1, textAlign: 'left', fontSize: '18px', color: theme.palette.grey[600], ml: 3 }}>
            Powered by Asia Mobiliti.
          </Typography>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
