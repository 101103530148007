import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useFormik, Form, FormikProvider } from 'formik';
import { useTheme } from '@mui/material/styles';
import { GoogleAuthProvider, signInWithPopup, deleteUser } from 'firebase/auth';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Box,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import SnackBar from '../../../components/Snackbar';
import { auth } from '../../../firebase';
import { APIGetAdminUserByFirebaseUUID, APIGetAdminUser } from '../../../utils/apiCalled';
import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [mainMsg, setMainMsg] = useState('');
  const [userFirebase, setUserFirebase] = useState(null);
  const theme = useTheme();

  // const LoginSchema = Yup.object().shape({
  //   email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  //   password: Yup.string().required('Password is required')
  // });

  // const submitTest = async (email, password) => {
  //   try {
  //     if (email === user.email && password === user.password){
  //       Cookies.set('userType', user.userType);
  //       Cookies.set('name', user.name);
  //       Cookies.set('email', user.email);
  //       Cookies.set('token', user.token);
  //       navigate('/dashboard/app', { replace: true });
  //     } else {
  //       setOpen(true);
  //       setErrMsg('Wrong username or password');
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setOpen(true);
  //     setErrMsg(error);
  //     setLoading(false);
  //   }
  // };

  // to called the GoogleSignIn function
  const googleSignIn = async () => {
    setLoading(true);
    // console.log('google sign innnnnn------>>>>>');
    const provide = new GoogleAuthProvider(auth); // this to get the google provider (token)
    // this to call start the google signIn
    signInWithPopup(auth, provide)
      .then((result) => {
        const user = result.user
        getAPICalled(user); // this to call the function if its admin user or not
        Cookies.set('isLogin', true); // this to store the email to displayed the is User Authenticated
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setOpen(true);
        setMainMsg('Error Encountered')
        setErrMsg(`${errorCode} : ${errorMessage}`);
        setLoading(false);
      })
  }

  // this to called the GetAdminUser API.
  const getAPICalled = async (user) => {
    // console.log('api called')
    try {
      // this API to checked if its an admin user
      const result = await APIGetAdminUser(setLoading, user.email);
      if (result.data.length > 0) {
        navigate('/dashboard/bookings', { replace: true });
        Cookies.set('name', `${result.data[0].firstName} ${result.data[0].lastName}`); // this to store the name to display the name in popup
        // console.log('name set')
        // console.log('test if name appears: ' + Cookies.get('name'))
        Cookies.set('email', result.data[0].email); // this to store the email to display the email in popup
        Cookies.set('userId', result.data[0]._id)
        Cookies.set('isLogin', true); // this to store the email to displayed the is User Authenticated
        // const user = auth.currentUser;
        // console.log(user);
      } else {
        // console.log('noooo result', result);
        // this to check of the auth current user have a phone number
        const user = auth.currentUser;
        if (user.phoneNumber) { // the reason to add in a checking to checked if the user is a passenger apps.
          signOut(auth).then(() => {
            // console.log('remove the signOut')
            navigate('/login', { replace: true });
            Cookies.remove('name'); // this to remove the cookies
            // console.log('name removed')
            Cookies.remove('email'); // this to remove the cookies
            Cookies.remove('userId'); // this to remove the cookies
            Cookies.remove('isLogin');
            setOpen(true);
            setErrMsg('This email is not registered as an admin');
            setMainMsg('Invalid Credentials');
            setLoading(false);
          }).catch((error) => {
            setOpen(true);
            setMainMsg('Error')
            setErrMsg('Error :', error);
            setLoading(false);
          })
        } else {
          // this to removed the auth user if the user are not an admin user and not a passenger apps user.
          deleteUser(user).then((user) => {
            Cookies.remove('name'); // this to remove the cookies 
            // console.log('name removed')
            Cookies.remove('email'); // this to remove the cookies
            Cookies.remove('userId'); // this to remove the cookies
            Cookies.remove('isLogin'); // this to remove the cookies
            setOpen(true);
            setErrMsg('This email is not registered as an admin');
            setMainMsg('Invalid Credentials');
            setLoading(false);
          }).catch((error) => {
            setOpen(true);
            setErrMsg('Error :', error);
            setMainMsg('Error')
            setLoading(false);
          })
          // Cookies.remove('name'); // this to remove the cookies 
          // Cookies.remove('email'); // this to remove the cookies
          // Cookies.remove('isLogin'); // this to remove the cookies
          // setOpen(true);
          // setErrMsg('This user are not an admin user');
          // setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setErrMsg(err);
      setLoading(false);
    }
  }

  // const formik = useFormik({
  //   initialValues: {
  //     email: '',
  //     password: '',
  //     remember: true
  //   },
  //   validationSchema: LoginSchema,
  //   onSubmit: (values) => {
  //     // navigate('/dashboard', { replace: true });
  //           /*
  //       ** API Login the parameter as per below
  //       @emailDecode - email that already encoded
  //       @passwordDecoed - password that already encoded
  //       @values.remember - remember me
  //     */
  //    submitTest(values.email, values.password);
  //   }
  // });

  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Box>
      <SnackBar alert="error" handleClose={() => setOpen(false)} open={open} errorMsg={errMsg} mainMsg={mainMsg} />
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="primary"
        sx={{
          border: '2px solid',
          borderColor: theme.palette.grey[300],
          borderRadius: '12px',
          color: theme.palette.grey[700],
        }}
        onClick={() => googleSignIn()}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <img height={24} src="/static/icons/google.svg" alt='google' />
          <div style={{ margin: '0px 0px 0px 16px'}}>Sign in with Google</div>
        </div>
      </LoadingButton>
    </Box>
  );
}
