import { Box, Typography, useTheme } from "@mui/material"
import Iconify from "./Iconify"

// Added as a reusable back button component for each of the pages
export default function BackButton({ back, type }) {

    const theme = useTheme()    

    const backButtonStyle = {
        padding: 2,
        pr: 1,
        pl: 0.5,
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s',
        '&:hover': {
            borderRadius: '12px',
            backgroundColor: theme => theme.palette.grey[200],
        },
        maxWidth: 'fit-content',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        color: theme.palette.grey[900]
    }
    
    return (
        <Box onClick={() => back()}>
        <Box sx={backButtonStyle}>
        <Iconify icon="tabler:arrow-left" width={24} height={24} />
            <Typography variant="body1" sx={{ pl: 1, fontWeight: 'fontWeightMedium' }}>
                {type === 'booking' && 'Back to Bookings List'}
                {type === 'vehicle' && 'Back to Vehicles List'}
                {type === 'driver' && 'Back to Drivers List'}
                {type === 'user' && 'Back to Admin Users List'}
                {type === 'verification' && 'Back to E-KYC Verification List'}
            </Typography>
        </Box>
        </Box>
    )
}