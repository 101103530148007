import React, { useState, useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Iconify from './Iconify';

// This component was created in order to allow for the dragging and dropping of files while converting them to Base64 format
const DragAndDropBase64 = ({ onFileUpload, setOpen, setVariant, setMainMsg, setErrMsg }) => {
    const [fileData, setFileData] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const theme = useTheme();

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file) {
            validateAndConvertToBase64(file);
        }
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            validateAndConvertToBase64(file);
        }
    };

    const validateAndConvertToBase64 = (file) => {
        const validTypes = ['image/svg+xml', 'image/png', 'image/jpeg', 'image/gif'];

        if (validTypes.includes(file.type)) {
            convertToBase64(file);
        } else {
            // Show error in Snackbar
            setMainMsg("Invalid file type");
            setErrMsg("Please upload only SVG, PNG, JPG, or GIF files.");
            setVariant("error");
            setOpen(true);
        }
    };

    const convertToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64Data = reader.result;
            setFileData(base64Data);

            if (onFileUpload) {
                onFileUpload(base64Data);
            }
        };
        reader.onerror = (error) => {
            console.error('Error converting file to Base64:', error);
        };
    };

    return (
        <Box
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClick}
            style={{
                ml: 4,
                border: '2px solid',
                borderColor: theme.palette.grey[200],
                borderRadius: '12px',
                padding: '30px 75px 30px 75px',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isDragging ? '#f0f8ff' : '#fff',
            }}
        >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 45,
                            height: 45,
                            borderRadius: '50%',
                            bgcolor: theme.palette.grey[200],
                            mb: 1
                        }}
                    >
                        <Iconify icon="lucide:cloud-upload" sx={{ color: theme.palette.grey[700] }} width={24} height={24} />
                    </Box>
                    <Typography variant='body1' sx={{ color: theme.palette.grey[600] }}>
                        <span style={{ fontWeight: 'bold', color: theme.palette.grey[700] }}>Click to upload</span> or drag and drop
                    </Typography>
                    <Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>
                        SVG, PNG, JPG or GIF (max. 800x400px)
                    </Typography>
                </Box>

            <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept="image/svg+xml, image/png, image/jpeg, image/gif"
                onChange={handleFileChange}
            />
        </Box>
    );
};

export default DragAndDropBase64;
