import { alpha, Box, Button, Typography, useTheme } from "@mui/material";
import plusFill from '@iconify-icons/eva/plus-fill';
import { Icon } from "@iconify/react";

export default function CreateButton({ handleOpen, type }) {
    const theme = useTheme();

    // Define the button style directly
    const confirmButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
          bgcolor: alpha(theme.palette.primary.main, 0.92)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
          bgcolor: theme.palette.primary.dark,
          color: 'white',
        },
      }

    return (
        <Button
            sx={confirmButtonStyle}
            startIcon={<Icon icon={plusFill} />}
            onClick={handleOpen}
        >
            <Typography sx={{ fontWeight: 'fontWeightMedium' }}>{`New ${type}`}</Typography>
        </Button>
    );
}
