import { alpha, Box, Button, FormControl, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material";
import Iconify from "../Iconify";
import ModalDelete from "../ModalDelete";
import { useEffect, useState } from "react";
import BackButton from "../backButton"


// New Vehicle Details component created to display vehicle information
export default function MoreVehicles({ deleteModal, handleCloseMoreVehicles, vehicle, handleDelete, handleOpenDeleteModal, handleCloseDeleteModal, handleOpenPageVehicles }) {

    const theme = useTheme();

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };

    const editButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.90)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.dark,
            color: 'white',
        },
    }

    const removeButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.error.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.error.main, 0.85)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.dark,
            color: 'white',
        },
    }


    const fontHeaderStyle = {
        color: theme.palette.grey[700],
        fontWeight: 'fontWeightMedium',
        mr: 5,
        minWidth: '250px'
    }

    const formControlStyle = {
        mb: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }

    function formatValidity(vehicle) {
        // Parse the date string using new Date()
        const validityDate = new Date(vehicle.permitValidity);

        // Check if the date is valid
        if (isNaN(validityDate.getTime())) {
            return "Invalid Date"; // Handle invalid dates
        }

        // Define options for formatting the date to include day, month, and year
        const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = validityDate.toLocaleDateString("en-US", dateOptions);

        return formattedDate; // Return formatted date
    }

    return (
        <>
            <Box sx={{ mt: 2 }}>
                <BackButton back={handleCloseMoreVehicles} type={'vehicle'} />
                <Box sx={{ mt: 1, pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    <Typography variant="h3">Vehicles</Typography>
                    <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>View all details of the vehicle</Typography>
                </Box>
                <Box sx={{ padding: 2, borderTop: '1px solid', borderColor: theme.palette.grey[300] }}>
                    <Box sx={{ width: '70%', mt: 2 }}>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Vehicle Number</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.vehicleNumber ? vehicle.vehicleNumber : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Vehicle Brand</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.brand ? vehicle.brand : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Vehicle Model</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.model ? vehicle.model : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Vehicle Color</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.colour ? vehicle.colour : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Current Driver</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.driverFirstName ? `${vehicle.driverFirstName} ${vehicle.driverLastName}` : 'Unassigned'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Permit Number</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.permitNumber ? vehicle.permitNumber : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Permit Validility</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.permitValidity ? formatValidity(vehicle) : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Total Seats</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.capacity ? vehicle.capacity : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Status</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.status ? vehicle.status : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Vehicle Type</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={vehicle.vehicleType ? vehicle.vehicleType : 'None'} />
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right', borderTop: '1px solid', borderColor: theme.palette.grey[300], pt: 3 }}>
                    <Button sx={buttonStyle} onClick={() => handleCloseMoreVehicles()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button sx={removeButtonStyle} onClick={() => handleOpenDeleteModal()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Remove Vehicle
                        </Typography>
                    </Button>
                    <Button sx={editButtonStyle} onClick={() => handleOpenPageVehicles(vehicle)}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Edit
                        </Typography>
                    </Button>
                </Box>
            </Box>
            {deleteModal && <ModalDelete deleteModal={deleteModal} handleDelete={handleDelete} handleClose={handleCloseDeleteModal} data={vehicle} />}
        </>
    )
}