// material
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// components
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  useTheme,
  alpha
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import TagsInput from '../components/TagsInput';
import MenuPopover from '../components/MenuPopover';
import SnackBar from '../components/Snackbar';
import { APICreateVehicle, APIDeleteVehicle, APIGetVehicle } from '../utils/apiCalled';
import CustomPagination from 'src/components/CustomPagination';
import MoreVehicles from 'src/components/dashboard/MoreVehicles';
import PageVehicle from 'src/components/dashboard/PageVehicle';
import CreateButton from 'src/components/CreateButton';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user && _user.length > 0 && _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'vehicleNumber', label: 'Vehicle Number', alignRight: false },
  { id: 'brand', label: 'Vehicle Brand', alignRight: false },
  { id: 'model', label: 'Vehicle Model', alignRight: false },
  { id: 'colour', label: 'Vehicle Color', alignRight: false },
  { id: 'capacity', label: 'Total Seats', alignRight: false },
  { id: 'isActive', label: 'Vehicle Status', alignRight: false },
  { id: '' }
];

const filterStatus = ['ACTIVE', 'INACTIVE'];
const filterType = ['VAN', 'BUS', 'MPV'];

const loadingStyle = {
  position: 'absolute',
  zIndex: 10,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export default function Vehicle() {
  const [page, setPage] = useState(0);
  const [modalAdd, setModalAdd] = useState(false); // this is for the display add modal click
  const [modalDel, setModalDel] = useState(false); // state for display delete modal
  const [modalEdit, setModalEdit] = useState(false);
  const [editState, setEditState] = useState(false);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openFilterPopUp, setOpenFilterPopUp] = useState(null); // filter popup
  // this to store the data when click one of the row
  const [detailsPage, setDetailsPage] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState('');
  const [mainMsg, setMainMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [succMsg, setSuccMsg] = useState(''); // this state for snackbar success message
  const [getVehicle, setGetVehicle] = useState([]);
  const [modalTriggerAPICalled, setModalTriggerAPICalled] = useState(false)
  const [apiCalledComponent, setApiCalledComponent] = useState(false);

  const [openMoreVehicles, setOpenMoreVehicles] = useState(false);
  const [openPageVehicles, setOpenPageVehicles] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false)

  const [getFilter, setGetFilter] = useState({
    vehicleNumber: null,
    vehicleType: null,
    brand: null,
    model: null,
    colour: null,
    status: null,
    permitNumber: null,
    capacity: null,
  });

  const [dupFilter, setDupFilter] = useState({
    vehicleNumber: [],
    vehicleType: [],
    brand: [],
    model: [],
    colour: [],
    status: [],
    permitNumber: [],
    capacity: [],
  });

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  }



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const closePopup = () => {
    setOpenFilterPopUp(null);
  };

  const clearFilterButton = () => {
    // console.log('go here to clear the filter button');
    setGetFilter({
      vehicleNumber: null,
      vehicleType: null,
      brand: null,
      model: null,
      colour: null,
      status: null,
      permitNumber: null,
      capacity: null,
    });
    setDupFilter({
      vehicleNumber: [],
      vehicleType: [],
      brand: [],
      model: [],
      colour: [],
      status: [],
      permitNumber: [],
      capacity: [],
    });
    setApiCalledComponent(true);  // this to called getAPI if buttonFiltered.
  };

  const getAPICalled = async (filter) => {
    try {
      const result = await APIGetVehicle(setLoading, filter);
      // console.log('result', result);
      if (result.status === 200) {
        const { data } = result;
        setGetVehicle(data);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setMainMsg('Error Encountered')
        setErrMsg('Error!, Please try again');
      }
    } catch (err) {
      // console.log('GO HERE IF ERROR', err);
      setLoading(false);
      setOpen(true);
      setMainMsg('Error Encountered')
      setVariant('error');
      setErrMsg('Error!', err);
    }
  };

  const theme = useTheme();

  // this to called api first page load
  useEffect(() => {
    getAPICalled(getFilter);
  }, [])

  // this to called API after click clear button
  useEffect(() => {
    if (apiCalledComponent) {
      getAPICalled(getFilter)
      setApiCalledComponent(false);
    }
  }, [apiCalledComponent])

  // this after click submit will called back the getVehicle API
  useEffect(() => {
    if (modalTriggerAPICalled) {
      getAPICalled(getFilter);
      setModalTriggerAPICalled(false)
    }
  }, [modalTriggerAPICalled])

  const handleSelectAllClick = (event) => {
    //   if (event.target.checked) {
    //     const newSelecteds = getDriver.map((n) => n.name);
    //     setSelected(newSelecteds);
    //     return;
    //   }
    //   setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };


  // snackbarClose
  const handleSnackBarClose = () => {
    setOpen(false);
  };

  const handleDelete = async (data) => {
    try {
      const result = await APIDeleteVehicle(setLoading, data._id);
      if (result.status === 200) {
        setLoading(false);
        setDeleteModal(false);
        setOpen(true);
        setVariant('success');
        setMainMsg('Vehicle Successfully Deleted');
        setSuccMsg(`Vehicle ${data.vehicleNumber} has been successfully deleted`);
        setOpenMoreVehicles(false);
        setModalTriggerAPICalled(true);
      } else {
        setLoading(false);
        setDeleteModal(false);
        setOpen(true);
        setMainMsg('Error Deleting Vehicle')
        setVariant('error');
        setErrMsg('Error deleting vehicle!, Please try again');
      }
    } catch (err) {
      setLoading(false);
      setModalDel(false);
      setOpen(true);
      setMainMsg('Error Deleting Vehicle')
      setVariant('error');
      setErrMsg('Error deleting vehicle!, Please try again');
    }
  };

  const filterButton = () => {
    getAPICalled(getFilter);
    setPage(0);
  };

  const handleOpenMoreVehicles = (vehicle) => {
    setSelectedVehicle(vehicle);
    setOpenMoreVehicles(true);
    setOpenPageVehicles(false);
  }


  const handleCloseMoreVehicles = () => {
    setSelectedVehicle(false);
    setOpenMoreVehicles(false);
    setOpenPageVehicles(false);
  }

  const handleOpenPageVehicles = (vehicle) => {
    setOpenMoreVehicles(false);
    setOpenPageVehicles(true);
    setSelectedVehicle(vehicle)
  }

  const handleOpenPageVehiclesCreate = () => {
    setOpenMoreVehicles(false);
    setOpenPageVehicles(true);
  }


  const buttonStyle = {
    border: '1px solid',
    borderColor: 'grey.300',
    px: 2,
    pt: 1,
    pb: 1,
    mr: 2,
    color: theme.palette.grey[700],
    backgroundColor: 'background.paper',
    '&:hover': {
      bgcolor: alpha(theme.palette.grey[500], 0.08),
    }
  };

  const confirmButtonStyle = {
    border: 'none',
    px: 2,
    pt: 1,
    pb: 1,
    mr: 2,
    bgcolor: theme.palette.primary.main,
    '&:hover': {
      bgcolor: alpha(theme.palette.primary.main, 0.92)
    },
    color: 'white',
    fontWeight: 'fontWeightMedium',
    '&.Mui-disabled': {
      bgcolor: theme.palette.primary.dark,
      color: 'white',
    },
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getVehicle.length) : 0;

  const filteredUsers = applySortFilter(getVehicle, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Vehicles">
      <Container maxWidth="xl" sx={{ mt: (!openMoreVehicles && !openPageVehicles) ? 12 : 0 }}>
        <SnackBar
          open={open}
          alert={variant}
          handleClose={handleSnackBarClose}
          errorMsg={errMsg}
          successMsg={succMsg}
          mainMsg={mainMsg}
        />
        {(!openMoreVehicles && !openPageVehicles) && <>
          <Box sx={{ pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <Typography variant="h3">Vehicles</Typography>
            <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>Manage Trek Rides vehicle details</Typography>
          </Box>
          {loading && <CircularProgress color='success' sx={loadingStyle} />}
          <Card>
            <Box sx={{ mr: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                popupfilter={setOpenFilterPopUp}
                labelName="Search Vehicle No.."
                displayFilter
              />
              <CreateButton type={'Vehicle'} handleOpen={handleOpenPageVehiclesCreate} />
            </Box>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={getVehicle.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <MenuPopover open={Boolean(openFilterPopUp)} anchorEl={openFilterPopUp} onClose={closePopup} styleWidth={500}>
                    <Box>
                      <Typography variant="subtitle1" sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 1 }}>Filter By</Typography>
                      <TagsInput fullWidth variant="outlined" id="vehicleNumber" name="vehicleNumber" placeholder="add tags" label="Vehicle Number" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="vehicleNumber" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                      <TagsInput fullWidth variant="outlined" id="brand" name="brand" placeholder="add tags" label="Brand" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="brand" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                      <TagsInput fullWidth variant="outlined" id="model" name="model" placeholder="add tags" label="Model" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="model" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                      <TagsInput fullWidth variant="outlined" id="colour" name="colour" placeholder="add tags" label="Color" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="colour" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                      <TagsInput fullWidth variant="outlined" id="status" name="status" placeholder="add tags" label="Status" type="autocomplete" selectList={filterStatus} dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="status" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                      <TagsInput fullWidth variant="outlined" id="vehicleType" name="vehicleType" placeholder="add tags" label="Vehicle Type" type="autocomplete" selectList={filterType} dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="vehicleType" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                      <TagsInput fullWidth variant="outlined" id="permitNumber" name="permitNumber" placeholder="add tags" type="text" label="Permit Number" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="permitNumber" setGetFilter={setGetFilter} getFilter={getFilter} sx={{ marginBottom: 1.5 }} />
                      <TagsInput fullWidth variant="outlined" id="capacity" name="capacity" placeholder="add tags" label="Total Seats" type="text" dupFilter={dupFilter} setDupFilter={setDupFilter} keyName="capacity" setGetFilter={setGetFilter} getFilter={getFilter} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, marginBottom: 2 }}>
                      <Button sx={{ ...buttonStyle, width: 130, marginRight: '8px' }} component={RouterLink} to="#" onClick={() => clearFilterButton()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>Clear Filter</Typography>
                      </Button>
                      <Button sx={{ ...confirmButtonStyle, width: 130 }} component={RouterLink} to="#" onClick={() => filterButton()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>Filter</Typography>
                      </Button>
                    </Box>
                  </MenuPopover>
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { _id, vehicleNumber, brand, model, colour, capacity, status } = row;
                        const isItemSelected = selected.indexOf(_id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left" component="th" scope="row" onClick={() => handleOpenMoreVehicles(row)}>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant='body2' sx={{ color: theme.palette.grey[900] }}>
                                  {vehicleNumber}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left" onClick={() => handleOpenMoreVehicles(row)}><Typography variant='body2' sx={{ color: theme.palette.grey[700] }}>{brand}</Typography></TableCell>
                            <TableCell align="left" onClick={() => handleOpenMoreVehicles(row)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{model}</Typography></TableCell>
                            <TableCell align="left" onClick={() => handleOpenMoreVehicles(row)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{colour}</Typography></TableCell>
                            <TableCell align="left" onClick={() => handleOpenMoreVehicles(row)}><Typography variant='body2' sx={{ color: theme.palette.grey[700] }}>{capacity}</Typography></TableCell>
                            <TableCell align="left" onClick={() => handleOpenMoreVehicles(row)}>
                              <Label
                                variant="ghost"
                                color={(status === 'INACTIVE' && 'error') || 'success'}
                              >
                                <Typography variant='body2' sx={{ fontWeight: 'fontWeightMedium' }}>{sentenceCase(status)}</Typography>
                              </Label>
                            </TableCell>

                            <TableCell align="right">
                              <UserMoreMenu
                                handleSeeMore={handleOpenMoreVehicles}
                                handleEditOpen={handleOpenPageVehicles}
                                data={row}
                                handleDeleteOpen={handleOpenDeleteModal}
                                handleClose={handleCloseDeleteModal}
                                deleteModal={deleteModal}
                                handleDelete={handleDelete}
                                setModalTriggerAPICalled={setModalTriggerAPICalled}
                                setSuccMsg={setSuccMsg}
                                setErrMsg={setErrMsg}
                                setVariant={setVariant}
                                setOpen={setOpen}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <CustomPagination page={page} totalNum={getVehicle.length} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} />
          </Card>
        </>}
        {openPageVehicles && <PageVehicle
          handleOpenPageVehicles={handleOpenPageVehicles}
          vehicle={selectedVehicle}
          setOpen={setOpen}
          setSuccMsg={setSuccMsg}
          setErrMsg={setErrMsg}
          setVariant={setVariant}
          setMainMsg={setMainMsg}
          setModalTriggerAPICalled={setModalTriggerAPICalled}
          handleCloseMoreVehicles={handleCloseMoreVehicles}
        />}
        {openMoreVehicles && <MoreVehicles
          handleCloseMoreVehicles={handleCloseMoreVehicles}
          vehicle={selectedVehicle}
          handleDelete={handleDelete}
          deleteModal={deleteModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleOpenPageVehicles={handleOpenPageVehicles}
        />}
      </Container>
    </Page>
  );
}